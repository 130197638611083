import React, { FC } from 'react';
import { useApplicationContext } from '../../../../core/hooks/useApplicationContext';
import { FloatingDropdown } from 'wix-ui-tpa/cssVars';
import { BusinessTimezoneTypes, SelectedTimezone } from '../../../../types';
import { classes, st } from './TimezoneDropdown.st.css';
import {
  TimezoneFormatType,
  useFormatters,
} from '../../../../core/hooks/useFormatters';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import Text, { TextType } from '../../Components/Text';
import { TimezoneDropdownDataHooks } from '../datahooks';

export const TimezoneDropdown: FC = () => {
  const {
    selectedTimezone,
    userInfo,
    businessInfo,
    actions: { setSelectedTimezone },
  } = useApplicationContext();

  const { getTimezoneName } = useFormatters();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  if (!businessInfo || !userInfo || !selectedTimezone) {
    return null;
  }

  const { timezone: userTimezone } = userInfo!;
  const { businessTimezone, allowTimezoneSwitch } = businessInfo!;

  const getTimezoneNameAndOffset = (timezone: string) => {
    return `${getTimezoneName(
      TimezoneFormatType.NAME,
      timezone,
    )} (${getTimezoneName(TimezoneFormatType.OFFSET, timezone)})`;
  };

  const getDropdownOption = (timezone: SelectedTimezone) => ({
    id: timezone.type,
    value: getTimezoneNameAndOffset(timezone.timezone),
    isSelectable: true,
  });

  const renderDynamicTimezone = () => (
    <FloatingDropdown
      data-hook={TimezoneDropdownDataHooks.Dropdown}
      className={classes.dropdown}
      label={t('app.my-bookings-widget.timezone-dropdown.label')}
      placeholder={getTimezoneNameAndOffset(selectedTimezone?.timezone!)}
      displayBlock={isMobile}
      onChange={(option: { id: BusinessTimezoneTypes }) => {
        setSelectedTimezone({
          type: option.id,
          timezone:
            option.id === BusinessTimezoneTypes.BUSINESS
              ? businessTimezone
              : userTimezone,
        });
      }}
      value={selectedTimezone.type}
      options={[
        getDropdownOption({
          type: BusinessTimezoneTypes.BUSINESS,
          timezone: businessTimezone,
        }),
        getDropdownOption({
          type: BusinessTimezoneTypes.USER,
          timezone: userTimezone,
        }),
      ]}
    />
  );

  const renderTimezoneName = () => (
    <Text
      type={TextType.Secondary}
      data-hook={TimezoneDropdownDataHooks.TimezoneText}
    >
      {t('app.my-bookings-widget.timezone-dropdown.label')}
      {getTimezoneNameAndOffset(selectedTimezone?.timezone!)}
    </Text>
  );

  const shouldRenderDropdown =
    allowTimezoneSwitch &&
    getTimezoneNameAndOffset(businessTimezone) !==
      getTimezoneNameAndOffset(userTimezone);

  return (
    <div
      data-hook={TimezoneDropdownDataHooks.Main}
      className={st(classes.root, { isMobile })}
    >
      {shouldRenderDropdown ? renderDynamicTimezone() : renderTimezoneName()}
    </div>
  );
};
